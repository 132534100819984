<template>
  <section class="section suggestion">
    <h1>{{ $t(brand) }}</h1>
    <div class="suggestion-text">
      <p class="text-h1">{{ $t(`suggestion_section.title.${page}`) }}</p>
      <p v-if="isStatistics && levelPlatinum" class="suggestion__statistics">
        {{ $t("facility", levelPlatinum.facility_amount) }}
        {{ $t("activity", levelPlatinum.activity_amount) }}
      </p>
      <p v-if="page !== User.members" class="text-h1 suggestion-text__subtitle">
        {{ $t(`suggestion_section.subtitle.${page}`) }}
      </p>
      <p class="suggestion__description text-h4">
        {{ $t(`suggestion_section.description.${page}`) }}
      </p>
      <BaseButton @click="showModal">
        {{ $t("Get an Offer") }}
      </BaseButton>
    </div>
    <div class="suggestion-images">
      <slot name="images"></slot>
    </div>
    <LazyModalsGetAnOfferFormModal
      v-if="isShowGetAnOfferFormModal"
      v-model:is-show-modal="isShowGetAnOfferFormModal"
      :cooperation-type="cooperationType"
    />
  </section>
</template>

<script lang="ts">
import { PropType } from "vue";

import { CooperationTypes, User } from "~/types/entities";

import BaseIcon from "~/components/icons/BaseIcon.vue";
import BaseButton from "~/components/button/BaseButton.vue";
import { SuggestionEvents } from "~/utils/analytics";

import { getStatistics } from "~/api/statisticsRequest";

export default defineNuxtComponent({
  components: {
    BaseIcon,
    BaseButton,
  },
  props: {
    page: {
      type: String as PropType<User>,
      required: true,
    },
    isStatistics: {
      type: Boolean,
      default: false,
    },
  },
  async setup() {
    const { locale } = useI18n();
    const { gtag } = useGtag();
    const { brand } = useRuntimeConfig().public;
    const route = useRoute();
    const localePath = useLocalePath();
    const cooperationType = ref<CooperationTypes | null>(null);

    const {
      levels: { platinum: levelPlatinum },
    } = await getStatistics(locale.value);

    onMounted(() => {
      if (route.fullPath === localePath("/companies")) {
        cooperationType.value = CooperationTypes.CONNECT_COMPANY;
      }
      if (route.fullPath === localePath("/partners")) {
        cooperationType.value = CooperationTypes.PARTNER;
      }
    });

    const isShowGetAnOfferFormModal = ref(false);

    const showModal = () => {
      gtag("event", SuggestionEvents.getOfferModal);
      isShowGetAnOfferFormModal.value = true;
    };

    return {
      isShowGetAnOfferFormModal,
      cooperationType,
      brand,
      showModal,
      CooperationTypes,
      User,
      levelPlatinum,
    };
  },
});
</script>
